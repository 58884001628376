export const GET_ERRORS = "GET_ERRORS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const DATA_LOADING = "DATA_LOADING";
export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_CHART_DATA_FILTERS = "GET_CHART_DATA_FILTERS";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const GET_EDA_CHART_DATA = "GET_EDA_CHART_DATA";
export const CLEAR_EDA_CHART_DATA = "CLEAR_EDA_CHART_DATA";
// export const CLEAR_FILTERS_DATA = 'CLEAR_FILTERS_DATA'
export const AUTO_PROMO_DATA = "AUTO_PROMO_DATA";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SET_TENANT_ID = "SET_TENANT_ID";
export const GET_EDA_CHART_DATA_FILTERS = "GET_EDA_CHART_DATA_FILTERS";
export const GET_EDA_DEPART_DATA_FILTERS = "GET_EDA_DEPART_DATA_FILTERS";
export const GET_EDA_ERRORS = "GET_EDA_ERRORS";
export const GET_BUDGET_DATA = "GET_BUDGET_DATA";
export const GET_BUDGET_CALENDAR_DATA = "GET_BUDGET_CALENDAR_DATA";
export const GET_BUDGET_GRAPH_DATA = "GET_BUDGET_GRAPH_DATA";
export const GET_BUDGET_EDIT = "GET_BUDGET_EDIT";
export const AUTO_GET_NEW_OPTIMIZED_DATA = "AUTO_GET_NEW_OPTIMIZED_DATA";
export const SKU_IDS = "SKU_IDS";
export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA";
export const GET_INSEASON_DATA = "GET_INSEASON_DATA";
export const GET_INSEASON_DATA_ERROR = "GET_INSEASON_DATA_ERROR";
export const GET_INSEASON_DATA_SUCCESS = "GET_INSEASON_DATA_SUCCESS";
export const INVENTORY_DATA_BY_FILTERED = "INVENTORY_DATA_BY_FILTERED";
export const SKU_COCKPIT_LINE_DATA = "SKU_COCKPIT_LINE_DATA";
export const GET_SKUSELECTION_DATA = "GET_SKUSELECTION_DATA";
export const SIMULATE_DATA_FOR_SKU = "SIMULATE_DATA_FOR_SKU";
export const FLUSH_COCKPIT_DATA = "FLUSH_COCKPIT_DATA";
// export const SHOW_ERROR = 'SHOW_ERROR'

/**
 * Ai Assisted in sseason
 */
export const GET_STORE_INVENTORY = "@@aiAssistedInSeason/GET_STORE_INVENTORY";
export const SIMULATE_CADENCE_SKU = "@@aiAssistedInSeason/SIMULATE_IN_SEASON";
export const FLUSH_STORE_DATA = "@@aiAssistedInSeason/FLUSH_STORE_DATA";
export const SIMULATE_CADENCE_SKU_LOADING =
  "@@aiAssistedInSeason/SIMULATE_CADENCE_SKU_LOADING";
export const SIMULATE_PRE_SEASON_MARKDOWN_DATA =
  "@@aiAssistedInSeason/SIMULATE_PRE_SEASON_MARKDOWN_DATA";
export const SAVE_OVERALL_MARKDOWN =
  "@@saveOverAllMarkdown/SAVE_OVERALL_MARKDOWN";
export const RESET_MARKDOWN_SAVED =
  "@@saveOverAllMarkdown/RESET_MARKDOWN_SAVED";
export const SAVE_SKU_MARKDOWN = "@@saveSKUMarkdown/SAVE_SKU_MARKDOWN";
// export const SHOW_ERROR = 'SHOW_ERROR'

export const GET_ALL_CASCADING_FILTERS = "GET_ALL_CASCADING_FILTERS";
export const GET_ALL_CASCADING_FILTERS_SUCCESS =
  "GET_ALL_CASCADING_FILTERS_SUCCESS";
export const GET_ALL_CASCADING_FILTERS_ERR = "GET_ALL_CASCADING_FILTERS_ERR";
export const PERSIST_CASCADING_FILTERS = "PERSIST_CASCADING_FILTERS";

// markdown calendar

export const GET_MARKDOWN_CALENDARDATA = "GET_MARKDOWN_CALENDARDATA";
export const GET_MARKDOWN_CALENDARPLOTDATA = "GET_MARKDOWN_CALENDARPLOTDATA";
//markdown config data
export const GET_MARKDOWN_CONFIG_DATA = "GET_MARKDOWN_CONFIG_DATA";
export const CREATE_MARKDOWN_SKU_GROUP = "CREATE_MARKDOWN_SKU_GROUP";
export const UPDATE_MARKDOWN_GROUP = "UPDATE_MARKDOWN_GROUP";
export const DELETE_MARKDOWN_GROUP = "DELETE_MARKDOWN_GROUP";
export const MARKDOWN_SKUGROUP_GRAPH_DATA = "MARKDOWN_SKUGROUP_GRAPH_DATA";

//pricing
export const GET_PRICING_TABLE_DATA = "GET_PRICING_TABLE_DATA";

//recommended testing
export const GET_RECCOMENDED_TESTING_FILTER_LOADING =
  "GET_RECCOMENDED_TESTING_FILTER_LOADING";
export const GET_RECCOMENDED_TESTING_FILTER_SUCCESS =
  "GET_RECCOMENDED_TESTING_FILTER_SUCCESS";
export const GET_RECCOMENDED_TESTING_FILTER_ERROR =
  "GET_RECCOMENDED_TESTING_FILTER_ERROR";

export const GENERIC_FILTER = "GENERIC_FILTER";
export const GENERIC_FILTER_SUCCESS = "GENERIC_FILTER_SUCCESS";
export const GENERIC_FILTER_ERR = "GENERIC_FILTER_ERR";

export const COMPETITORS_PRICING = "COMPETITORS_PRICING";
export const COMPETITORS_PRICING_SUCCESS = "COMPETITORS_PRICING_SUCCESS";
export const COMPETITORS_PRICING_FAILURE = "COMPETITORS_PRICING_FAILURE";

// markdown global promo data
export const GET_MARKDOWN_GLOBAL_PROMO_DATA =
  "@@globalPromo/GET_MARKDOWN_GLOBAL_PROMO_DATA";
export const SAVE_GLOBAL_PROMO = "@@globalPromo/SAVE_GLOBAL_PROMO";
export const GET_MARKDOWN_GLOBAL_PROMO_DATA_PER_MARKDOWN =
  "GET_MARKDOWN_GLOBAL_PROMO_DATA_PER_MARKDOWN";
export const LOADER = "LOADER";

// Actual Vs Planned Reports
export const GET_PLANNED_REPORTS_SUCCESS = "GET_PLANNED_REPORTS_SUCCESS";
export const GET_PLANNED_REPORTS_ERROR = "GET_PLANNED_REPORTS_ERROR";

// Summary
export const SET_KPI_DATA = "SET_KPI_DATA";

//Admin
export const SET_ROLE_OPTIONS = "SET_ROLE_OPTIONS";
export const SET_ATTRIBUTE_OPTIONS = "SET_ATTRIBUTE_OPTIONS";
export const SET_PERMISSION_OPTIONS = "SET_PERMISSION_OPTIONS";
export const SET_USER_OPTIONS = "SET_USER_OPTIONS";
export const SET_GROUP_OPTIONS = "SET_GROUP_OPTIONS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_GROUPS = "SET_GROUPS";
export const SET_USER_ROLE_GROUP_DATA = "SET_USER_ROLE_GROUP_DATA";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_TOTAL_ROLES = "SET_TOTAL_ROLES";
export const SET_GRP_DATA_FETCH_STATUS = "SET_GRP_DATA_FETCH_STATUS";
export const SET_ROLES = "SET_ROLES";
export const SET_USERS = "SET_USERS";
export const SET_ROLE_DATA_FETCH_STATUS = "SET_ROLE_DATA_FETCH_STATUS";
export const SET_USER_DATA_FETCH_STATUS = "SET_USER_DATA_FETCH_STATUS";
export const SET_PERMISSION_DATA_STATUS = "SET_PERMISSION_DATA_STATUS";

//ProductFilters
export const CHANGE_FILTER_DATA = "CHANGE_FILTER_DATA";

//Layout
export const LAYOUT_FETCHED = "LAYOUT_FETCHED";
export const SET_ROUTES = "SET_ROUTES";
export const SET_HEADER_MENU = "SET_HEADER_MENU";
export const SET_SIDEBAR_MENU = "SET_SIDEBAR_MENU";
//Tenant Settings
export const SET_TENANT_APPS = "SET_TENANT_APPS";

//Notifications
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATION_DND = "SET_NOTIFICATION_DND";
export const SET_WSCLIENT = "SET_WSCLIENT";
export const SET_USER_NOTIFICATION_TYPES = "SET_USER_NOTIFICATION_TYPES";

//Workflow
export const GET_STATES = "GET_STATES";
export const GET_TRANSITIONS = "GET_TRANSITIONS";
export const GET_WORKFLOWS = "GET_WORKFLOWS";
export const GET_TRANSITION_APPROVALS = "GET_TRANSITION_APPROVALS";

//Ticketing
export const SET_TICKET = "SET_TICKET";

//Tenant Info
export const TENANT_FETCH_INIT = "TENANT_FETCH_INIT";
export const TENANT_FETCH_SUCCESS = "TENANT_FETCH_SUCCESS";
export const TENANT_FETCH_FAILED = "TENANT_FETCH_FAILED";

//User Session Info
export const CHECK_USER_SESSION_INIT = "CHECK_USER_SESSION_INFO_INIT";

//EmailLogin
export const EMAIL_LOGIN_FAILED = "EMAIL_LOGIN_FAILED";

//Snackbar
export const ADD_SNACK = "ADD_SNACK";
export const REMOVE_SNACK = "REMOVE_SNACK";
export const CLOSE_SNACK = "CLOSE_SNACK";

//filter
export const GET_FILTER_KEY = "GET_FILTER_KEY";
export const SET_FILTER_MODULES = "SET_FILTER_MODULES";
export const SET_SELECTED_MODULES = "SET_SELECTED_MODULES";
export const SET_SELECTED_APPLICATION = "SET_SELECTED_APPLICATION";
export const SHOW_FILTER_MODAL_LOADER = "SHOW_FILTER_MODAL_LOADER";
export const SET_MODULE_CONFIG_DATA = "SET_MODULE_CONFIG_DATA";
export const SET_EDIT_MODULE_CONFIG_DATA = "SET_EDIT_MODULE_CONFIG_DATA";
export const SET_CONFIG_NAME = "SET_CONFIG_NAME";
export const ADD_CONFIG = "ADD_CONFIG";
export const RESET_CONFIG = "RESET_CONFIG";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const SET_CONFIGS = "SET_CONFIGS";
export const SET_MANDATORY_FILTERS_FOR_SCREEN =
  "SET_MANDATORY_FILTERS_FOR_SCREEN";

//Unit Definition
export const SET_STYLE_TABLE_DATA = "SET_STYLE_TABLE_DATA";
export const UNIT_DEFN_LOADER = "UNIT_DEFN_LOADER";
export const SET_STYLE_TABLE_COLS = "SET_STYLE_TABLE_COLS";
export const SET_SELECTED_STYLE_ID = "SET_SELECTED_STYLE_ID";
export const SET_UNIT_DEFINITION_TYPE = "SET_UNIT_DEFINITION_TYPE";
export const SET_UNIT_DEFINITION_NAME = "SET_UNIT_DEFINITION_NAME";
export const SET_UNIT_ATTRIBUTE_TYPE = "SET_UNIT_ATTRIBUTE_TYPE";
export const SET_STYLE_ATTRIBUTES = "SET_STYLE_ATTRIBUTES";
export const SET_COPY_STYLE_ID = "SET_COPY_STYLE_ID";
export const SET_PUD_AVAILABLE_DEFINITIONS = "SET_PUD_AVAILABLE_DEFINITIONS";
export const SET_SELECTED_AVAILABLE_DEFINITION =
  "SET_SELECTED_AVAILABLE_DEFINITION";
export const SET_PACK_QUANTITY = "SET_PACK_QUANTITY";
export const SET_PACKS_IN_CARTONS = "SET_PACKS_IN_CARTONS";
export const SET_CARTONS_IN_BOXES = "SET_CARTONS_IN_BOXES";
export const SET_PACKS_DISABLED = "SET_PACKS_DISABLED";
export const SET_PACKS_IN_CARTONS_DISABLED = "SET_PACKS_IN_CARTONS_DISABLED";
export const SET_CARTONS_IN_BOXES_DISABLED = "SET_CARTONS_IN_BOXES_DISABLED";
export const SET_ATTRIBUTE_DATA = "SET_ATTRIBUTE_DATA";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_SELECTED_FILTER_CONFIGURATION =
  "SET_SELECTED_FILTER_CONFIGURATION";
export const RESET_SELECTED_FILTER_CONFIGURATION =
  "RESET_SELECTED_FILTER_CONFIGURATION";
export const SET_IS_SAVED_FILTER_MAPPING_INVALID =
  "SET_IS_SAVED_FILTER_MAPPING_INVALID";
export const RESET_SET_IS_SAVED_FILTER_MAPPING_INVALID =
  "RESET_SET_IS_SAVED_FILTER_MAPPING_INVALID";
export const SET_SAVED_FILTER_SELECTION = "SET_SAVED_FILTER_SELECTION";
export const GET_SAVED_FILTERSELECTION_LOADING =
  "GET_SAVED_FILTERSELECTION_LOADING";

export const SET_SHOW_STYLES_TABLE = "SET_SHOW_STYLES_TABLE";
export const RESET_UNIT_DEFN_DATA = "RESET_UNIT_DEFN_DATA";
export const SET_AVAILABLE_STYLES = "SET_AVAILABLE_STYLES";
export const LAST_EDITED_DETAILS = "LAST_EDITED_DETAILS";
export const SET_UNIT_DEFN_FILTER_VALUES = "SET_UNIT_DEFN_FILTER_VALUES";
export const SET_STYLES_PAGE_STATUS = "SET_STYLES_PAGE_STATUS";
export const CHANGE_DATA_STATUS = "CHANGE_DATA_STATUS";
export const UNIT_NAME_VALIDITY_STATUS = "UNIT_NAME_VALIDITY_STATUS";
export const SET_EDIT_NAME = "SET_EDIT_NAME";

//Product Group
export const SET_PRODUCT_GROUP_DEFINITIONS = "SET_PRODUCT_GROUP_DEFINITIONS";
export const SET_PRODUCT_GROUPS = "SET_PRODUCT_GROUPS";
export const SET_SELECTED_PRODUCT_GROUP_TYPE =
  "SET_SELECTED_PRODUCT_GROUP_TYPE";
// export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_PRODUCT_GROUP_FILTERS = "SET_PRODUCT_GROUP_FILTERS";
export const SET_PRODUCT_GROUP_FILTERED_PRODUCTS =
  "SET_PRODUCT_GROUP_FILTERED_PRODUCTS";
export const SET_PRODUCT_GROUP_FILTERED_COLUMNS =
  "SET_PRODUCT_GROUP_FILTERED_COLUMNS";
export const SET_SELECTED_MANUAL_FILTER_TYPE =
  "SET_SELECTED_MANUAL_FILTER_TYPE";
export const SET_SELECTED_MANUAL_GROUP_DEFNS =
  " SET_SELECTED_MANUAL_GROUP_DEFNS";
export const PRODUCT_GROUP_LOADER = "PRODUCT_GROUP_LOADER";
export const SET_MAPPED_DEFINITIONS = "SET_MAPPED_DEFINITIONS";
export const SET_SELECTED_PRODUCTS = "SET_SELECTED_PRODUCTS";
export const SET_DATA_CHANGE_STATUS = "SET_DATA_CHANGE_STATUS";
export const RESET_PRODUCT_GROUP_FILTERED_PRODUCTS =
  "RESET_PRODUCT_GROUP_FILTERED_PRODUCTS";
export const SET_GROUPS_TABLE_COLUMNS = "SET_GROUPS_TABLE_COLUMNS";
export const SET_SELECTED_GROUP_PRODUCTS = "SET_SELECTED_GROUP_PRODUCTS";
export const SET_MANUAL_SELECTED_GROUPS = "SET_MANUAL_SELECTED_GROUPS";
export const DELETED_PRODUCTS_IN_EDIT_GROUP = "DELETED_PRODUCTS_IN_EDIT_GROUP";
export const EXISTING_EDIT_SELECTED_PRODUCTS =
  "EXISTING_EDIT_SELECTED_PRODUCTS";
export const DELETED_PRODUCTS_IN_DEFINITION = "DELETED_PRODUCTS_IN_DEFINITION";
export const ADD_PRODUCTS_IN_EDIT_GROUP = "ADD_PRODUCTS_IN_EDIT_GROUP";
export const SET_VIEW_GROUP_FILTER_VALUES = "SET_VIEW_GROUP_FILTER_VALUES";
export const ADD_GROUPS_IN_EDIT_GROUP = "ADD_GROUPS_IN_EDIT_GROUP";
export const DELETED_GROUPS_IN_EDIT_GROUP = "DELETED_GROUPS_IN_EDIT_GROUP";
export const VIEW_DEFNS_TABLE_COLS = "VIEW_DEFNS_TABLE_COLS";
export const DEFN_MAP_TABLE_COLS = "DEFN_MAP_TABLE_COLS";
export const SET_STYLE_LVL_TABLE_COLS = "SET_STYLE_LVL_TABLE_COLS";
export const SET_STYLE_LVL_TABLE_DATA = "SET_STYLE_LVL_TABLE_DATA";
export const SET_MANUAL_SELECTED_STYLES = "SET_MANUAL_SELECTED_STYLES";
export const DELETED_STYLES_IN_DEFINITION = "DELETED_STYLES_IN_DEFINITION";
export const SET_SELECTED_OBJECTIVE_METRIC = "SET_SELECTED_OBJECTIVE_METRIC";
export const SET_SELECTED_OBJECTIVE_TIME_FORMAT =
  "SET_SELECTED_OBJECTIVE_TIME_FORMAT";
export const SET_REQUEST_TABLE_COLUMNS = "SET_REQUEST_TABLE_COLUMNS";
export const SET_PROD_INITIAL_METRIC_FILTERS =
  "SET_PROD_INITIAL_METRIC_FILTERS";
export const SET_METRIC_START_DATE = "SET_METRIC_START_DATE";
export const SET_METRIC_END_DATE = "SET_METRIC_END_DATE";
export const SET_SELECTED_CLUSTER = "SET_SELECTED_CLUSTER";
export const RESET_PRODUCT_FILTERS_TABLE = "RESET_PRODUCT_FILTERS_TABLE";
//Product Status
export const SET_PRODUCT_STATUS = "SET_PRODUCT_STATUS";

//Product mapping
export const SET_PRODUCT_PO_MAPPING = "SET_PRODUCT_PO_MAPPING";
export const SET_PRODUCT_ASN_MAPPING = "SET_PRODUCT_ASN_MAPPING";
export const SET_PRODUCT_DC_MAPPING = "SET_PRODUCT_DC_MAPPING";
export const SET_PRODUCT_FC_MAPPING = "SET_PRODUCT_FC_MAPPING";
export const SET_ALL_DCS = "SET_ALL_DCS";
export const SET_ALL_FCS = "SET_ALL_FCS";

//Table Component
export const SAVE_TABLE_STATE = " SAVE_TABLE_STATE";
export const SAVE_LAST_SEARCH_TAB = "SAVE_LAST_SEARCH_TAB";

//store grouping
export const SET_STORE_GROUPS = "SET_STORE_GROUPS";
export const STORE_GROUP_LOADER = "STORE_GROUP_LOADER";
export const SET_SELECTED_STORES = "SET_SELECTED_STORES";
export const SET_STORE_GROUP_FILTERS = "SET_STORE_GROUP_FILTERS";
export const SET_STORE_GROUP_FILTERED_COLUMNS =
  "SET_STORE_GROUP_FILTERED_COLUMNS";
export const SET_SELECTED_STORE_GROUP_TYPE = "SET_SELECTED_STORE_GROUP_TYPE";
export const SET_STORE_GROUP_FILTERED_STORES =
  "SET_STORE_GROUP_FILTERED_STORES";
export const DELETE_STORE_GROUP = "DELETE_STORE_GROUP";
export const DELETED_STORES_IN_EDIT_GROUP = "DELETED_STORES_IN_EDIT_GROUP";
export const SET_MANUAL_SELECTED_STORE_GROUPS =
  "SET_MANUAL_SELECTED_STORE_GROUPS";
export const SET_STORE_GROUPS_TABLE_COLUMNS = "SET_STORE_GROUPS_TABLE_COLUMNS";
export const DELETED_STORE_GROUPS_IN_EDIT_GROUP =
  "DELETED_STORE_GROUPS_IN_EDIT_GROUP";
export const ADD_STORE_GROUPS_IN_EDIT_GROUP = "ADD_STORE_GROUPS_IN_EDIT_GROUP";
export const RESET_STORE_GROUP_FILTERED_STORES =
  "RESET_STORE_GROUP_FILTERED_STORES";
export const ADD_STORES_IN_EDIT_GROUP = "ADD_STORES_IN_EDIT_GROUP";
export const EXISTING_EDIT_SELECTED_STORES = "EXISTING_EDIT_SELECTED_STORES";
export const SET_STORE_VIEW_GROUP_FILTER_VALUES =
  "SET_STORE_VIEW_GROUP_FILTER_VALUES";
export const SET_STORE_GROUP_UNSELECTED_ROWS_IN_SELECT_ALL =
  "SET_STORE_GROUP_UNSELECTED_ROWS_IN_SELECT_ALL";
export const SET_SELECT_ALL_STORES_STATE = "SET_SELECT_ALL_STORES_STATE";
// event configuration
export const SET_EVENTS = "SET_EVENTS";

//tenant Config
export const SET_HIERARCHY_LEVEL = "SET_HIERARCHY_LEVEL";
export const SET_HELP_DESK = "SET_HELP_DESK";
export const SET_SPECIFIC_SCREEN_NAME = "SET_SPECIFIC_SCREEN_NAME";

//User Management Constants
export const SET_USER_SCREENS = "SET_USER_SCREENS";
export const SET_ACTIVE_SIDEBAR_DATA = "SET_ACTIVE_SIDEBAR_DATA";

export const SET_USER_APPS = "SET_USER_APPS";
export const SET_USER_MANAGEMENT_LIST = "SET_USER_MANAGEMENT_LIST";
export const SET_ACTIVE_APP_NAME = "SET_ACTIVE_APP_NAME";
export const SET_USER_APPS_PERMISSION = "SET_USER_APPS_PERMISSION";

// user respectives side bar screens for plansamrt

export const SET_USER_PLATFORM_SCREENS = "SET_USER_PLATFORM_SCREENS";
export const SET_APPLICATION_CODES_LIST = "SET_APPLICATION_CODES_LIST";

// For Productivity Helper 
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_DATASET = "SET_DATASET";
export const SET_TABLES = "SET_TABLES";
export const SET_TABLE_DESC = "SET_TABLE_DESC";
export const UPDATE_TABLE_DESC_SUCCESS = "UPDATE_TABLE_DESC_SUCCESS";
export const SET_TABLE_COLUMN_DESC = "SET_TABLE_COLUMN_DESC";
export const UPDATE_TABLE_COLUMN_DESCRIPTION_SUCCESS = "UPDATE_TABLE_COLUMN_DESCRIPTION_SUCCESS";
export const SET_SCHEMA_DATA = "SET_SCHEMA_DATA";
export const SET_PROMPT_DATA = "SET_PROMPT_DATA";
export const SET_QUERY_DATA = "SET_QUERY_DATA";
export const PRODUCTIVITY_LOADER = 'PRODUCTIVITY_LOADER';
export const PRODUCTIVITY_SCHEMA_LOADER = 'PRODUCTIVITY_SCHEMA_LOADER';
export const SET_PYTHON_PROMPT = "SET_PYTHON_PROMPT";

export const SET_JIRA_PROJECTS = "SET_JIRA_PROJECTS";
export const SET_EPIC = "SET_EPIC";
export const SET_STORIES = "SET_STORIES";
export const SET_STORIES_DESC = "SET_STORIES_DESC";
export const SET_QA_PROMPT = "SET_QA_PROMPT";
export const SET_TEST_CASE = "SET_TEST_CASE";

export const SET_EXCEL_DATA = "SET_EXCEL_DATA";
export const RESET_EXCEL_DATA = "RESET_EXCEL_DATA";
export const SET_TASKS = "SET_TASKS";
export const SET_TASK_DESC = "SET_TASK_DESC";
export const SET_TASK_TEST_CASE = "SET_TASK_TEST_CASE";
export const SET_TASK_PROMPT = "SET_TASK_PROMPT";
export const SET_LAST_PROMPT = "SET_LAST_PROMPT";

//data-ingestion
export const SET_DATA_SOURCES = "SET_DATA_SOURCES";
export const GET_DATA_SOURCES = "GET_DATA_SOURCES";
export const SET_DATA_INGESTION_WSCLIENT = "WSCLIENT";
export const SET_INGESTION_DATA = "SET_INGESTION_DATA";
export const UPDATE_SUBMODULE_STATUS = "UPDATE_SUBMODULE_STATUS";
export const SET_MODULE_INFO = "SET_MODULE_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_AIRFLOW_USER_FLAG = "SET_AIRFLOW_USER_FLAG";
export const SET_ACTIVE_MODULE = "SET_ACTIVE_MODULE";
export const SET_INGESTION_PROCESS_SWITCH_STATUS = "SET_INGESTION_PROCESS_SWITCH_STATUS";
export const SET_INGESTION_SWITCH_STATUS = "SET_INGESTION_SWITCH_STATUS";
export const SET_INGESTION_BUTTON = "SET_INGESTION_BUTTON";
export const SET_INGESTION_REPORT_BUTTON = "SET_INGESTION_REPORT_BUTTON";
export const SET_INGESTION_FAILED_STATUS_FLAG = "SET_INGESTION_FAILED_STATUS_FLAG";
export const SET_INGESTION_PAUSE_STATUS_FLAG = "SET_INGESTION_PAUSE_STATUS_FLAG";
export const SET_CONFIG_COMPLETED = "SET_CONFIG_COMPLETED";

//JSON PARSER
export const INVOKE_API = "INVOKE_API";
export const CREATE_REDUCER_STATE = "CREATE_REDUCER_STATE";
export const UPDATE_REDUCER_STATE = "UPDATE_REDUCER_STATE";
export const CREATE_PARSER_REDUCER_STATE = "CREATE_PARSER_REDUCER_STATE";
export const UPDATE_PARSER_REDUCER_STATE = "UPDATE_PARSER_REDUCER_STATE";

