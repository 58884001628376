import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Container, Tooltip } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import DataIngestionConfigTable from "./dataIngestionConfigTable";
import SourceMappingConfigTable from "./sourceMappingConfigTable";
import {
  DATAPLATFORM_SOURCING_BULK_VALIDATION,
  DATAPLATFORM_SOURCING_QUERY_VALIDATION,
} from "modules/dataIngestion/constants-dataIngestion/apiConstants";
import BulkValidation from "./BulkValidation";
import AddSourceMappingConfig from "./AddSourceMappingConfig";
import { addSnack } from "../../../../actions/snackbarActions";
import {
  createMappingTableConfig,
  addConnectorTableConfig,
  testConnector,
  getConnectorTableConfigById,
  updateConnectorById,
  getModuleLevelAccess,
  deleteConnectorConfigById,
  deleteMappingConfig,
  uploadMappingTableConfig,
  saveUploadedMappingTableConfig
} from "../../services-dataIngestion/dataIngestion-combined-service";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  CREATE,
  DATA_SOURCE_MODULE,
  SOURCING_CONFIGURATION_MODULE,
  DATA_SOURCES,
  SOURCING_CONFIGURATION,
  MAPPING_TABLE,
  EDIT,
  DELETE
} from "modules/dataIngestion/constants-dataIngestion/moduleConstants";
import DataSourcesTable from "./dataSourcesTable";
import AddConnectorModal from "./AddConnectorModal";
import { newConnectorFormFields } from "modules/dataIngestion/constants-dataIngestion/connectorFormFields";
import { saveMappingTableConfig } from "actions/tenantConfigActions";
import ViewModal from "./ViewModal";
import { DeleteOutline, ModeEditOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import UploadHandler from "commonComponents/uploadHandler";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { MAPPING_TABLE_COLS } from "modules/dataIngestion/constants-dataIngestion/csvCols";
import { handleUploadCSV } from "modules/dataIngestion/common-components/functions/common_functions";

const Header = (props) => {
  const globalClasses = globalStyles();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(
    false
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const validationHandler = useRef();

  const attachCallBacks = (callback) => {
    validationHandler.current = { validate: callback };
  };

  const handleUpload = async (parsedData, file) => {
    handleUploadCSV(parsedData, file, MAPPING_TABLE_COLS, uploadHandler, validationHandler, props.addSnack);
  };

  const uploadHandler = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await props.uploadMappingTableConfig(formData);
      props.setCsvUploadedData([...res?.data || []]);
      setIsModalOpen(false);
      props.setRenderMappingAgGrid(false);
    } catch (error) {
      if (error.response?.data?.data?.length) {
        validationHandler.current.validate(error.response?.data?.data);
      } else {
        props.addSnack({
          message: error?.data?.message || "Something went wrong.",
          options: {
            variant: "error",
          },
        });
        validationHandler.current.validate([]);
      }
    }
  }

  return (
    <div
      className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
    >
      <ConfirmPrompt
        showModal={showDeleteConfirmModal}
        title="Confirm Delete"
        message="Are you sure you want to delete the config ?"
        ariaLabeledBy="save-changes-dialog"
        primaryBtnText="Yes"
        secondaryBtnText="No"
        setConfirm={setShowDeleteConfirmModal}
        confirmCallback={(val) => {
          if (val) {
            props.configurationTab === MAPPING_TABLE ? props.deleteMappingConfig(props.selectedRows[0]) : props.deleteConnectorConfig(props.selectedRows[0]);
          }
        }}
      />
      <Typography variant="h4">Configurations</Typography>
      {props.configurationTab !== SOURCING_CONFIGURATION
        ? props.userRole &&
        <div>
          {props.configurationTab === MAPPING_TABLE && (<Button
            color="primary"
            variant="contained"
            // size="small"
            title={"Upload"}
            id="uploadGroups"
            style={{ marginRight: '16px', padding: "5px 16px" }}
            onClick={() => setIsModalOpen(true)}
          >
            <FileUploadIcon />
          </Button>)}
          <UploadHandler
            handleUpload={handleUpload}
            isModalOpen={isModalOpen}
            hideDownloadTemplateButton={true}
            setIsModalOpen={setIsModalOpen}
            attachCallBacks={attachCallBacks}
            jsonUpload={true}
            templateConfig={[]}
            uploadInstructions={[]}
            tenantUploadConfig={{}}
            onlyShowCSVUpload={true}
            templateName="StoreGroupTemplate"
          />
          {props.userRole[CREATE] && <Button
            variant="contained"
            color="primary"
            onClick={() => props.onClickAddConfigHandler(true)}
          // startIcon={<AddIcon />}
          >
            {props.configurationTab === MAPPING_TABLE
              ? "Add Row"
              : "Add Connector"}
          </Button>}
          {props.selectedRows.length > 0 && <>{props.userRole[EDIT] && <Button style={{ marginLeft: 4 }} variant="outlined" onClick={() => props.editHandler(props.selectedRows[0])}><ModeEditOutline /></Button>} {props.userRole[DELETE] && <Button variant="outlined" onClick={() => { setShowDeleteConfirmModal(true) }}><DeleteOutline /></Button>}</>}</div>

        : props.userRole &&
        (
          <span>
            {" "}

            {props.userRole[CREATE] && (<><Button
              id="bulkValidate"
              variant="contained"
              color="primary"
              onClick={props.onClickBulkHandler}
              style={{ marginLeft: "1rem" }}
            >
              Bulk Validate
            </Button></>)}

            <Button
              id="bulkValidate"
              variant="contained"
              color="primary"
              onClick={() => { props.setVisibilityFlag(prevValue => !prevValue) }}
              style={{ marginRight: "1rem", marginLeft: "1rem" }}
            >
              {props.visibilityFlag ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </Button>


          </span>
        )}
    </div>
  );
};
const Configuration = (props) => {
  let [showBulkValidationModal, setShowBulkValidationModal] = useState(false);
  let [showAddConfigModal, setShowAddConfigModal] = useState(false);
  const [renderAgGrid, setRenderAgGrid] = useState(true);
  const [renderMappingAgGrid, setRenderMappingAgGrid] = useState(true);
  let [
    showIntermediateQueryValidationModal,
    setShowIntermediateQueryValidationModal,
  ] = useState(false);
  let [queryPayload, setQueryPayload] = useState([]);
  let [userScreenLevelAccess, setUserScreenLevelAccess] = useState({});
  let [editFormData, setEditFormData] = useState(null);
  let [connectorTableData, setConnectorTableData] = useState([]);
  let [loader, setLoader] = useState(false);
  let [viewModal, setViewModal] = useState(false)
  const [selectedView, setselectedView] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [csvUploadedData, setCsvUploadedData] = useState([]);
  const [visibilityFlag, setVisibilityFlag] = useState(false);

  useEffect(async () => {
    const userAccessData =
      props.configurationTab === DATA_SOURCES
        ? await props.getModuleLevelAccess("dataingestion", DATA_SOURCE_MODULE)
        : await props.getModuleLevelAccess(
          "dataingestion",
          SOURCING_CONFIGURATION_MODULE
        );
    setUserScreenLevelAccess(userAccessData.data.data);
  }, [props.configurationTab]);

  useEffect(() => {
    if (!renderMappingAgGrid) {
      setRenderMappingAgGrid(true);
    }
  }, [renderMappingAgGrid]);

  const getSourceConfigTooltipData = (options) => {
    return options.map(option => {
      let metaData = Object.keys(option?.otherData).filter(key => !["file_extension", "file_extension_options", "file_prefix"].includes(key))
      return {
        "value": option.value,
        "label": <Tooltip placement="left" title={<React.Fragment>
          <Typography color="inherit">Source config details:</Typography>
          <hr />
          <div>{metaData.map(config => {
            return <div component="h6" variant="h6">{config} : {option.otherData[config]}</div>
          })}</div>
        </React.Fragment>} arrow value={option.value}><span>{option.label}</span></Tooltip>
      }
    })
  }


  const newFormFields = [
    // {
    //   fc_code: 280,
    //   label: "Tenant",
    //   column_name: "tenant",
    //   type: "non-cascaded",
    //   display_type: "TextField",
    //   level: null,
    //   dimension: "product",
    //   is_mandatory: true,
    //   is_multiple_selection: false,
    //   range_min: null,
    //   range_max: null,
    //   default_value: null,
    //   is_disabled: false,
    //   is_clearable: false,
    //   display_order: 1,
    //   is_required: true,
    //   extra: {
    //     helperText: "Enter a valid tenant name",
    //   },
    // },
    {
      fc_code: 283,
      label: "Connector",
      column_name: "connector",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 1,
      is_required: true,
      options: [
        { label: "GCS", value: "GCS" },
        { label: "MSSQL", value: "MSSQL" },
        { label: "SFTP", value: "SFTP" },
        { label: "Snowflake", value: "Snowflake" },
      ],
      extra: {
        helperText: "Enter a valid connector name",
      },
    },
    {
      fc_code: 284,
      label: "Source config",
      column_name: "source_config",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 2,
      is_required: true,
      extra: {
        helperText: "Enter a valid source config name",
      },
      options: props.dataSourcesDetails ? getSourceConfigTooltipData(props.dataSourcesDetails.map(data => {
        let removedMaskedConfig = Object.assign(...Object.keys(data.value)
          .filter(key => key !== "password")
          .map(key => ({ [key]: data.value[key] })));
        return { "label": data.name, "value": data.name, "otherData": { ...removedMaskedConfig } }
      })) : [],
    },
    {
      fc_code: 282,
      label: "View",
      column_name: "view",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 3,
      is_required: true,
      button: true,
      extra: {
        helperText: "Enter a valid view name",
      },
    },
    {
      fc_code: 281,
      label: "Table",
      column_name: "table",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 4,
      is_required: true,
      extra: {
        helperText: "Enter a valid table name",
      },
    },
    {
      fc_code: 285,
      label: "Pull Type",
      column_name: "pull_type",
      type: "non-cascaded",
      display_type: "dropdown",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 6,
      is_required: true,
      options: [
        { label: "incremental", value: "incremental" },
        { label: "full", value: "full" },
      ],
      extra: {
        helperText: "Select a valid pull type name",
      },
    },
    {
      fc_code: 286,
      label: "Replace",
      column_name: "replace",
      type: "non-cascaded",
      display_type: "ToogleField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 7,
      is_required: true,
      extra: {
        helperText: "Enter a valid replace value",
      },
    },
    {
      fc_code: 287,
      label: "Schedule Interval",
      column_name: "schedule_interval",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 8,
      is_required: true,
      extra: {
        helperText: "Enter a valid schedule interval name",
      },
    },
    {
      fc_code: 288,
      label: "Intermediate Table",
      column_name: "intermediate_table",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 9,
      is_required: true,
      extra: {
        helperText: "Enter a valid intermediate table name",
      },
    },
    {
      fc_code: 289,
      label: "Filter",
      column_name: "filter",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 10,
      is_required: true,
      extra: {
        helperText: "Enter a valid filter name",
      },
    },
    {
      fc_code: 290,
      label: "Dataingestion Filterparam",
      column_name: "dataingestion_filterparam",
      type: "non-cascaded",
      display_type: "DateAndTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 11,
      is_required: true,
      extra: {
        helperText: "Enter a valid dataingestion filter param name",
        dateFormat: "YYYY-MM-DDTHH:mm:ss"
      },
    },
    {
      fc_code: 291,
      label: "Filter Param",
      column_name: "filter_param",
      type: "non-cascaded",
      display_type: "DateAndTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 12,
      is_required: true,
      extra: {
        helperText: "Enter a valid filter param name",
        dateFormat: "YYYY-MM-DDTHH:mm:ss"
      },
    },
    {
      fc_code: 292,
      label: "Extraction sync date Interval",
      column_name: "extraction_sync_dt",
      type: "non-cascaded",
      display_type: "DateAndTimeField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 13,
      is_required: true,
      extra: {
        helperText: "Enter a valid extraction sync date interval name",
        dateFormat: "YYYY-MM-DDTHH:mm:ss"
      },
    },
    {
      fc_code: 293,
      label: "Source Format Regex",
      column_name: "source_format_regex",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 14,
      is_required: true,
      extra: {
        helperText: "Enter a valid source format regex name",
      },
    },
    {
      fc_code: 295,
      label: "Partition Column",
      column_name: "partition_column",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 16,
      is_required: false,
      extra: {
        helperText: "Enter a valid partition_column name",
      },
    },
    {
      fc_code: 296,
      label: "Clustering Columns",
      column_name: "clustering_columns",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 17,
      is_required: false,
      extra: {
        helperText: "Enter a valid clustering columns name",
      },
    },
    {
      fc_code: 297,
      label: "Query Partitioning Threshold",
      column_name: "query_partitioning_threshold",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 18,
      is_required: true,
      extra: {
        helperText: "Enter a valid query partitioning threshold name",
      },
    },
    {
      fc_code: 294,
      label: "Inter query exec order",
      column_name: "inter_query_exec_order",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 15,
      is_required: true,
      extra: {
        helperText: "Enter a valid inter query exec order number",
      },
    },
    {
      fc_code: 298,
      label: "Num Partitions",
      column_name: "num_partitions",
      type: "non-cascaded",
      display_type: "IntegerField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 19,
      is_required: false,
      extra: {
        helperText: "Enter a valid num partitions name",
      },
    },
    {
      fc_code: 299,
      label: "Db Partition Column",
      column_name: "db_partition_column",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 20,
      is_required: false,
      extra: {
        helperText: "Enter a valid db partition column name",
      },
    },
    {
      fc_code: 300,
      label: "Field Delimiter",
      column_name: "field_delimiter",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: true,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 21,
      is_required: true,
      extra: {
        helperText: "Enter a valid field delimiter",
      },
    },
    {
      fc_code: 286,
      label: "Replace special characters",
      column_name: "replace_special_characters",
      type: "non-cascaded",
      display_type: "ToogleField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 7,
      is_required: false,
      extra: {
        helperText: "Enter a valid replace special characters value",
      },
    },
    {
      fc_code: 300,
      label: "Row count validation info",
      column_name: "row_count_validation_info",
      type: "non-cascaded",
      display_type: "TextField",
      level: null,
      dimension: "product",
      is_mandatory: false,
      is_multiple_selection: false,
      range_min: null,
      range_max: null,
      default_value: null,
      is_disabled: false,
      is_clearable: false,
      display_order: 21,
      is_required: false,
      extra: {
        helperText: "Enter a valid row count validation info",
      },
    },
  ];

  const id = props.id;

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const onClickBulkHandler = async () => {
    setShowBulkValidationModal(true);
  };

  const onClickQueryandler = async () => {
    setShowIntermediateQueryValidationModal(true);


  };

  const onClickAddConfigHandler = async () => {
    setShowAddConfigModal(true);
  };

  const toogleRenderAgGrid = () => {
    setRenderAgGrid((previousValue) => !previousValue);
  };

  const getQueryAttributes = (attributes) => {
    // attribute.attribute_name === "derived_tables_queries_loc" || attribute.attribute_name === "intermediate_queries"
    let attributes_payload = attributes.data
      .filter(
        (attribute) =>
          attribute.attribute_name === "intermediate_queries" ||
          attribute.attribute_name === "intermediate_queries_loc"
      )
      .map((attribute) => {
        return {
          attribute_value: attribute.attribute_value,
          attribute_name: attribute.attribute_name,
        };
      });
    setQueryPayload(attributes_payload);
  };

  const testConnector = async (data) => {
    let body = {
      ...data,
    };
    try {
      let response = await props.testConnector(body);
      if (response.status) {
        displaySnackMessages("Test connection passed!", "success");
      } else displaySnackMessages(response.message, "error");
    } catch (err) {
      if (!isEmpty(err.response.data?.message)) {
        displaySnackMessages(err.response.data?.message, "error");
      } else {
        displaySnackMessages("Test connection failed!", "error");
      }
    }
  };

  const saveNewMappingRowAdded = async (data) => {
    let body = {
      ...data,
    };
    setLoader(true)
    try {
      let response =
        props.configurationTab === DATA_SOURCES
          ? await props.addConnectorTableConfig(body)
          : await props.createMappingTableConfig(body);
      if (response.status) {
        displaySnackMessages(
          response.message || "New row added successfully",
          "success"
        );
        setShowAddConfigModal(false);
      } else displaySnackMessages(response.message, "error");
    } catch (err) {
      setLoader(false)
      if (!isEmpty(err.response.data?.message)) {
        displaySnackMessages(err.response.data?.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
    setLoader(false)
  };

  const updateConfigs = async (data) => {
    // setRenderAgGrid(false);
    // props.setUserMgmtLoader(true);
    let body = {
      ...data
    }
    setLoader(true)
    try {
      let response = props.configurationTab === DATA_SOURCES ? await props.updateConnectorById(
        editFormData?.data_source_config_id,
        body
      ) : await props.saveMappingTableConfig(
        // props.appInfo?.applicationCode,
        0,
        data
      );
      if (response.status) {
        displaySnackMessages(
          response.message || "Successfully Updated config",
          "success"
        );
        setShowAddConfigModal(false);
        setEditFormData(null);
      } else displaySnackMessages(response.message, "error");
    } catch (err) {
      setLoader(false)
      if (!isEmpty(err.response.data?.message)) {
        displaySnackMessages(err.response.data?.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
    setLoader(false)
  };

  const editHandler = async (data) => {
    if (props.configurationTab === MAPPING_TABLE) {
      let formDefaultValues = {}
      Object.keys(data).filter(key => !["tenant", "created_at", "created_by", "is_deleted", "deleted_at", "deleted_by", "updated_at", "updated_by"].includes(key)).map(key => {
        formDefaultValues[key] = data[key]
      })
      setEditFormData({ ...formDefaultValues })
      setShowAddConfigModal(true);
    }
    else {
      setEditFormData({
        data_source_config_id: data?.data_source_config_id,
        name: data?.name,
        type: data?.type,
      });
      setShowAddConfigModal(true);
      try {
        let response = await props.getConnectorTableConfigById(
          data.data_source_config_id
        );
        if (response.status) {
          let formDetails = {
            data_source_config_id: response.data[0].data_source_config_id,
            name: response.data[0].name,
            type: response.data[0].type,
            ...response.data[0].value,
          };
          setEditFormData(formDetails);
        } else
          displaySnackMessages(
            response.message || "Something went wrong",
            "error"
          );
      } catch (err) {
        if (!isEmpty(err.response.data?.message)) {
          displaySnackMessages(err.response.data?.message, "error");
        } else {
          displaySnackMessages("Failed to fectch config details", "error");
        }
      }
    }
  };

  const deleteConnectorConfig = async (data) => {
    try {
      // setLoadingStatus(true);
      let payload = {
        name: data?.name,
        type: data?.type,
        value: {},
      };
      await props.deleteConnectorConfigById(
        data?.data_source_config_id,
        payload
      );
      // setLoadingStatus(false);
      setRenderMappingAgGrid(false);
      props.addSnack({
        message: "Deleted connector successfully",
        options: {
          variant: "success",
        },
      });
    } catch (error) {
      // setLoadingStatus(false);
      props.addSnack({
        message: "Failed to delete the connector",
        options: {
          variant: "error",
        },
      });
    }
  };

  const deleteMappingConfig = async (data) => {
    const { table, view } = data;
    try {
      // setLoadingStatus(true);
      let payload = {
        table,
        view,
      };
      await props.deleteMappingConfig(payload);
      setRenderMappingAgGrid(false);
      // setLoadingStatus(false);
      props.addSnack({
        message: "Deleted mapping configuration successfully",
        options: {
          variant: "success",
        },
      });
    } catch (error) {
      // setLoadingStatus(false);
      props.addSnack({
        message: "Failed to delete the mapping configuration",
        options: {
          variant: "error",
        },
      });
    }
  };

  const onSaveHandler = async () => {
    try {
      const res = await props.saveUploadedMappingTableConfig({
        data: [...csvUploadedData]
      });
      if (res?.status) {
        props.addSnack({
          message:
            res.message || "Please wait for notification to be received shortly",
          options: {
            variant: "success"
          }
        });
        setRenderMappingAgGrid(false);
        setCsvUploadedData([])
      } else {
        props.addSnack({
          message: res?.message || "Something went wrong.",
          options: {
            variant: "error"
          }
        });
      }
    } catch (error) {
      console.log(error)
      props.addSnack({
        message: error.response?.data?.message || "Something went wrong.",
        options: {
          variant: "error",
        },
      });
    }
  }


  return (
    <Container maxWidth={false}>
      <Header
        id={id}
        configurationTab={props.configurationTab}
        onClickAddConfigHandler={onClickAddConfigHandler}
        onClickQueryandler={onClickQueryandler}
        onClickBulkHandler={onClickBulkHandler}
        userRole={userScreenLevelAccess}
        editHandler={editHandler}
        selectedRows={selectedRows}
        deleteConnectorConfig={deleteConnectorConfig}
        deleteMappingConfig={deleteMappingConfig}
        addSnack={props.addSnack}
        setCsvUploadedData={setCsvUploadedData}
        setRenderMappingAgGrid={setRenderMappingAgGrid}
        uploadMappingTableConfig={props.uploadMappingTableConfig}
        visibilityFlag={visibilityFlag}
        setVisibilityFlag={setVisibilityFlag}

      />
      {props.title === 'Intermediate' &&
        <Button
          id="bulkValidate"
          variant="contained"
          color="primary"
          style={{ marginLeft: "45%", marginBottom: "20px", marginTop: "-7.3%" }}
          onClick={onClickQueryandler}


        >

          Intermediate Queries Validator
        </Button>
      }
      {props.configurationTab === SOURCING_CONFIGURATION
        && renderAgGrid && (
          <DataIngestionConfigTable
            renderTable={toogleRenderAgGrid}
            id={id}
            location={props.location}
            getQueryAttributes={getQueryAttributes}
            userRole={userScreenLevelAccess}
            visibilityFlag={visibilityFlag}
            title={props.title}
          />
        )}
      {props.configurationTab === DATA_SOURCES
        && !showAddConfigModal &&
        renderMappingAgGrid && (
          <DataSourcesTable
            renderTable={toogleRenderAgGrid}
            id={id}
            location={props.location}
            getQueryAttributes={getQueryAttributes}
            userRole={userScreenLevelAccess}
            onEdit={editHandler}
            setRenderMappingAgGrid={setRenderMappingAgGrid}
            setTableData={(data) => setConnectorTableData(data)}
            setSelectedRows={setSelectedRows}
          />
        )}
      {props.configurationTab === MAPPING_TABLE && !showAddConfigModal &&
        renderMappingAgGrid && (
          <SourceMappingConfigTable
            id={id}
            setRenderMappingAgGrid={setRenderMappingAgGrid}
            getSourceConfigTooltipData={getSourceConfigTooltipData}
            onEdit={editHandler}
            setViewModal={setViewModal}
            setselectedView={setselectedView}
            setSelectedRows={setSelectedRows}
            csvUploadedData={csvUploadedData}
          />
        )}
      {csvUploadedData && csvUploadedData.length > 0 && <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSaveHandler()}
          disabled={!(csvUploadedData?.[0])}
        // startIcon={<AddIcon />}
        >
          Save
        </Button></div>}

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {showAddConfigModal &&
          (props.configurationTab === MAPPING_TABLE ? (
            <AddSourceMappingConfig
              newFormFields={newFormFields}
              onApply={(data) => saveNewMappingRowAdded(data)}
              handleClose={() => {
                setShowAddConfigModal(false);
                setEditFormData(null);
              }}
              configurationTab={props.configurationTab}
              editFormData={editFormData}
              updateMappingTableData={updateConfigs}
              tableData={connectorTableData}
              loaderMappingTable={loader}
              setselectedView={setselectedView}
              setViewModal={setViewModal}
            />
          ) : (
            <AddConnectorModal
              newFormFields={newConnectorFormFields}
              onApply={(data) => saveNewMappingRowAdded(data)}
              testConnector={(data) => testConnector(data)}
              handleClose={() => {
                setShowAddConfigModal(false);
                setEditFormData(null);
              }}
              configurationTab={props.configurationTab}
              editFormData={editFormData}
              updateConnector={updateConfigs}
              tableData={connectorTableData}
            />
          ))}

        {(showBulkValidationModal ||
          showIntermediateQueryValidationModal) && (
            <BulkValidation
              handleClose={() =>
                showIntermediateQueryValidationModal
                  ? setShowIntermediateQueryValidationModal(false)
                  : setShowBulkValidationModal(false)
              }
              dataUrl={
                showIntermediateQueryValidationModal
                  ? `${DATAPLATFORM_SOURCING_QUERY_VALIDATION}/${props.title.toLowerCase()}_sourcing_configuration/attributes`
                  : `${DATAPLATFORM_SOURCING_BULK_VALIDATION}/${props.title.toLowerCase()}_sourcing_configuration`
              }
              showIntermediateQueryValidationModal={
                showIntermediateQueryValidationModal
              }
              queryPayload={queryPayload}
            />
          )}
        {
          viewModal && <ViewModal
            setViewModal={setViewModal}
            selectedView={selectedView} />
        }
      </div>
    </Container>
  );
};

Header.propTypes = {
  configurationTab: PropTypes.string,
  deleteConnectorConfig: PropTypes.func,
  selectedRows: PropTypes.array,
  deleteMappingConfig: PropTypes.func,
  userRole: PropTypes.object,
  editHandler: PropTypes.func,
  addSnack: PropTypes.func,
  uploadMappingTableConfig: PropTypes.func,
  setCsvUploadedData: PropTypes.func,
  setRenderMappingAgGrid: PropTypes.func,
  visibilityFlag: PropTypes.bool,
  setVisibilityFlag: PropTypes.func
};

Configuration.propTypes = {
  id: PropTypes.any.isRequired,
  configurationTab: PropTypes.string,
  addSnack: PropTypes.func,
  deleteMappingConfig: PropTypes.func,
  deleteConnectorConfigById: PropTypes.func,
  saveUploadedMappingTableConfig: PropTypes.func,
  uploadMappingTableConfig: PropTypes.func,
  title: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    dataSourcesDetails: state.dataIngestionReducer.dataSourcesDetails
  };
};
const mapActionsToProps = {
  addSnack,
  createMappingTableConfig,
  getModuleLevelAccess,
  addConnectorTableConfig,
  testConnector,
  getConnectorTableConfigById,
  updateConnectorById,
  saveMappingTableConfig,
  deleteConnectorConfigById,
  deleteMappingConfig,
  uploadMappingTableConfig,
  saveUploadedMappingTableConfig
};

export default connect(mapStateToProps, mapActionsToProps)(Configuration);
