//api constants

// Tenanat config API URLs
export const DATAINGESTION_TENANT_CONFIG_GET_ALL = "data-platform/configuration/sourcing_configuration";
export const INGESTION_CONFIG_GET_ALL = "data-platform/configuration/ingestion_configuration";
export const DATAPLATFORM_SOURCING_CONFIG = "/data-platform/sourcing"
export const DATAPLATFORM_SOURCING_TRIGGERS_CONFIG = "/data-platform/generic_trigger_mapping"
export const DATAPLATFORM_CONFIG = "/data-platform/data-ingestion";
export const DATAPLATFORM_INGESTION_CONFIG = "/data-platform/configuration"
export const DATAPLATFORM_GENERIC_MAPPING_TABLE_CONFIG = "/data-platform/table-list";
export const DATAPLATFORM_DERIVED_TABLE = "/data-platform/derived-table";
export const DATAPLATFORM_TABLE_SCHEMA = "/data-platform/table-schemas";

export const DATAPLATFORM_SOURCING_MAPPING_CONFIG = "/data-platform/source_mapping";
export const DATAPLATFORM_GENERIC_MASTER_MAPPING = "/data-platform/generic-master-mapping";
export const DATAPLATFORM_INGESTION_GENERIC_MASTER_MAPPING = "/data-platform/generic_master_mapping";
export const DATAPLATFORM_GENERIC_SCHEMA_MAPPING = "/data-platform/generic_schema_mapping";
export const DATAPLATFORM_GENERIC_MASTER_MAPPING_TABLE_LIST = "/data-platform/generic-master-mapping/mapping";
export const DATAPLATFORM_DERIVED_TABLE_MAPPING = "/data-platform/derived_tables_mapping";

export const DATAPLATFORM_SOURCING_MAPPING_UPLOAD_CONFIG = "/data-platform/sourcing_mapping/upload";
export const DATAPLATFORM_SOURCING_MAPPING_SAVE_CONFIG = "/data-platform/sourcing_mapping/save";
export const DATAPLATFORM_GENERIC_MASTER_MAPPING_UPLOAD_CONFIG = "/data-platform/generic-master-mapping/upload";
export const DATAPLATFORM_SOURCING_MASTER_MAPPING_SAVE_CONFIG = "/data-platform/generic-master-mapping/save";
export const DATAPLATFORM_GENERIC_SCHEMA_MAPPING_UPLOAD_CONFIG = "/data-platform/generic-schema-mapping/upload/csv";
export const DATAPLATFORM_GENERIC_SCHEMA_MAPPING_SAVE_CONFIG = "/data-platform/generic-schema-mapping/save";
export const DATAPLATFORM_QC_MODULE_KPI_MASTER_UPLOAD_CONFIG = "/data-platform/qc_module/kpi_master/upload";
export const DATAPLATFORM_QC_MODULE_KPI_MASTER_SAVE_CONFIG = "/data-platform/qc_module/kpi_master/save";
export const DATAPLATFORM_QC_MODULE_RULE_MASTER_UPLOAD_CONFIG = "/data-platform/qc_module/rule_master/upload";
export const DATAPLATFORM_QC_MODULE_RULE_MASTER_SAVE_CONFIG = "/data-platform/qc_module/rule_master/save";
export const DATAPLATFORM_DERIVED_TABLE_UPLOAD_CONFIG = "/data-platform/derived_tables_mapping/upload";
export const DATAPLATFORM_DERIVED_TABLE_SAVE_CONFIG = "/data-platform/derived_tables_mapping/save";
export const DATAPLATFORM_TRIGGERS_UPLOAD_CONFIG = "/data-platform/generic_trigger_mapping/upload";
export const DATAPLATFORM_TRIGGER_SAVE_CONFIG = "/data-platform/generic_trigger_mapping/save";

export const DATAPLATFORM_SOURCING_BULK_VALIDATION = "/data-platform/validation";
export const DATAPLATFORM_INGESTION_BULK_VALIDATION = "/data-platform/ingestion_validation";
export const DATAPLATFORM_INGESTION_QUERY_VALIDATION = "/data-platform/ingestion_validation/attributes";
export const DATAPLATFORM_SOURCING_QUERY_VALIDATION = "/data-platform/validation";
export const DATAPLATFORM_SOURCING_MAPPING_BULK_VALIDATION = "/data-platform/validation/generic-master-mapping";
export const DATAPLATFORM_INGESTION_QUERY_GENERATOR = "data-platform/configuration/ingestion_configuration/query_generator/";

export const DATAINGESTION_QC_MODULE_KPI_MASTER_GET_ALL = "data-platform/qc_module/kpi_master";
export const DATAINGESTION_QC_MODULE_RULE_MASTER_GET_ALL = "data-platform/qc_module/rule_master";
export const DATAINGESTION_QC_MODULE_CUSTOM_QUERY_GET_ALL = "data-platform/qc_module/fetch_custom_qc";
export const DATAINGESTION_QC_MODULE_CUSTOM_QUERY_GET_TABLE_LIST = "data-platform/qc_module/fetch_table_info";
export const DATAINGESTION_QC_MODULE = "/data-platform/qc_module"

export const DATAPLATFORM_DATA_TRANSFER = "/data-platform/data-transfer";
export const DATAPLATFORM_DATA_TRANSFER_JOBS = "/data-platform/jobs";
export const DATAPLATFORM_DATA_TRANSFER_SUBMIT_ENDPOINT = "/data-platform/etl/submit-job";
export const DATAPLATFORM_DATA_SCHEDULE_SUBMIT_ENDPOINT = "/data-platform/etl/schedule-dataflow-job";
export const DATAPLATFORM_DATA_SCHEDULE_VALIDATE_ENDPOINT = "/data-platform/etl/validate-target";
export const DATAPLATFORM_DATA_TRANSFER_GET_DYNAMIC_FIELDS = "/data-platform/etl/get-pipeline-options";
export const DATAPLATFORM_DATA_TRANSFER_GET_JOB_CONFIG_DYNAMIC_FIELDS = "/data-platform/etl/return-configs";
export const DATAPLATFORM_DATA_TRANSFER_GET_TOTAL_JOB_COUNT = "/data-platform/etl/get-total-job-count";
export const DATAPLATFORM_DATA_TRANSFER_GET_JOB_LIST = "/data-platform/etl/get-all-jobs";

export const DATAPLATFORM_DATA_SOURCE = "/data-platform/data-source";
export const DATAPLATFORM_TEST_CONNECTOR_CONFIG = "/data-platform/test-data-source";

export const DATAPLATFORM_DATA_SOURCE_MAPPING = "/data-platform/source_mapping";
export const DATAPLATFORM_GET_SAMPLE_DATA = "data-platform/etl/get-sample-data";
export const DATAPLATFORM_TURNAROUND_TIME_REPORT = "/data-platform/reports";
export const DATAPLATFORM_BIGQUERY_COST_REPORT = "/data-platform/big-query-summary/dates";

export const DATA_PLATFORM_TIME_TRACKER_DAGS_LIST = "/data-platform/airflow/time-tracker/dags-list"
export const DATA_PLATFORM_DAG_IDS = "/data-platform/airflow/time-tracker/dag-run-details"
export const DATA_PLATFORM_DAG_INFO = "data-platform/airflow/time-tracker/dag-run-info"
export const DATA_PLATFORM_COST_DAG_IDS = "/data-platform/big-query-summary/dates"
export const DATA_PLATFORM_DAG_COST_INFO = "data-platform/big-query-summary/dag/tasks"


export const DATA_PLATFORM_MODULES_CONFIG = "data-platform/module-pf";
export const DATA_PLATFORM_PAUSE_PIPELINE = "data-platform/sample-ingestion/pause";
export const DATA_PLATFORM_RESUME_PIPELINE = "data-platform/sample-ingestion/resume";
export const DATA_PLATFORM_CLEAR_TASK = "data-platform/sample-ingestion/clear";
export const DATA_PLATFORM_START_PIPELINE = "data-platform/sample-ingestion/start";
export const DATA_PLATFORM_SAMPLE_INGESTION_DATA = "data-platform/sample-ingestion/data";
export const DATA_PLATFORM_UPDATE_SUBMODULE_STATUS = "data-platform/sub-module-update";
export const DATA_PLATFORM_AIRFLOW_USER_INFO = "data-platform/airflow-user-role";
export const DATA_PLATFORM_CREATE_AIRFLOW_USER = "data-platform/airflow-um/create";
export const DATA_PLATFORM_UPDATE_AIRFLOW_USER = "data-platform/airflow-um/update";

export const DATAPLATFORM_HOME = "/data-platform/home"
export const DATA_PLATFORM_SAMPLE_INGESTION_REPORT = "data-platform/sample-ingestion/report/"//"data-platform/sample-ingestion/report";

export const DATA_PLATFORM_SUBMIT_QUERY = "/data-platform/review/submit"
export const DATA_PLATFORM_VIEW_CONTENT = "/data-platform/view-content"
export const DATA_PLATFORM_VIEW_ALL_PRS = "data-platform/view-all-prs"
export const DATA_PLATFORM_GET_APPROVERS_LIST = "data-platform/review/get_approvers"
export const DATA_PLATFORM_APPROVE_PR = "data-platform/review/approve_pr"
export const DATA_PLATFORM_DECLINE_PR = "data-platform/review/decline_pr"
export const DATA_PLATFORM_VALIDATE_PR = "data-platform/validate_pr"
export const DATA_PLATFORM_MERGE_PR = "data-platform/review/merge_pr" 
export const DATA_PLATFORM_MERGE_CONFLICT_PR = "data-platform/review/diff"
export const DATA_PLATFORM_ADD_COMMENT_REVIEW_WORKFLOW = "data-platform/review/add-comment" 
export const DATA_PLATFORM_VIEW_ALL_COMMENTS_REVIEW_WORKFLOW = "data-platform/review/list-comments"
export const DATA_PLATFORM_REPLY_TO_COMMENT_REVIEW_WORKFLOW = "data-platform/review/reply-to-comment"

export const DATA_PLATFORM_SAVE_SAMPLE_SELECTION = "data-platform/sample-ingestion/create-extraction-queries"
export const DATA_PLATFORM_GET_SAMPLE_SELECTION_VIEW_CONFIGS = "data-platform/sample-ingestion/views-columns"