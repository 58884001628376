import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Loader from "Utils/Loader/loader";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import globalStyles from "Styles/globalStyles";
import { Button, Grid } from "@mui/material";
import Form from "Utils/form";
import { addSnack } from "../../../../actions/snackbarActions";
import {
  gcsConnectorFormFields,
  mysqlConnectorFormFields,
  sftpConnectorFormFields,
  snowflakeConnectorFormFields,
} from "modules/dataIngestion/constants-dataIngestion/connectorFormFields";
import { TENANT } from "config/api";

const common = {
  __ConfirmBtnText: "Yes",
  __RejectBtnText: "No",
};

const useStyles = makeStyles(() => ({
  form: {
    alignItems: "flex-end",
    display: "flex",
    marginBottom: "3rem",
  },
  tableAlignment: {
    textAlign: "center",
    margin: "auto",
    width: "80%",
  },
  customMinHeight: {
    minHeight: "11rem",
    border: "none",
  },
}));

const AddConnectorModal = (props) => {
  // using common and individual styles of the component
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [newRowFormData, setNewRowFormData] = useState({});
  const [addRowTableData, setAddRowTableData] = useState([]);
  const [newRowFormFields, setNewRowFormFields] = useState([]);
  const [loader, setLoader] = useState(false);
  const [closeAddRowModalConfirm, setCloseAddRowModalConfirm] = useState(false);

  useEffect(async () => {
    setLoader(true);
    if (props.editFormData) {
      let editFormDetails = props.editFormData;
      if (editFormDetails?.type === "gcs")
        dynamicFormFields([...props.newFormFields, ...gcsConnectorFormFields]);
      else if (editFormDetails?.type === "mssql")
        dynamicFormFields([
          ...props.newFormFields,
          ...mysqlConnectorFormFields,
        ]);
      else if (editFormDetails?.type === "sftp")
        dynamicFormFields([...props.newFormFields, ...sftpConnectorFormFields]);
      else if (editFormDetails?.type === "snowflake")
        dynamicFormFields([
          ...props.newFormFields,
          ...snowflakeConnectorFormFields,
        ]);
      setNewRowFormData({ ...props.editFormData });
    } else {
      dynamicFormFields(props.newFormFields);
    }
  }, [props.editFormData]);

  const dynamicFormFields = (formFields) => {
    const newFormFields = formFields.map((data) => {
      return {
        ...data,
        required: data.is_required,
        error: false,
        accessor: data.column_name,
        field_type: data.display_type,
        value_type: data.column_name === "password" ? "password" : "text",
        isDisabled: props.editFormData
          ? ["name", "type"].includes(data.column_name)
            ? true
            : false
          : false,
        prefix: (props.editFormData && data.column_name) === "name" ? false : data.column_name === "name" ? true : false 
      };
    });
    setLoader(false);
    setNewRowFormFields(newFormFields);
  };

  const closeAddNewConnectorModal = () => {
    props.handleClose();
  };

  const handleDetailsOnChange = (updateFormValue) => {
    if (newRowFormData?.type !== updateFormValue?.type) {
      if (updateFormValue?.type === "gcs")
        dynamicFormFields([...props.newFormFields, ...gcsConnectorFormFields]);
      else if (updateFormValue?.type === "mssql")
        dynamicFormFields([
          ...props.newFormFields,
          ...mysqlConnectorFormFields,
        ]);
      else if (updateFormValue?.type === "sftp")
        dynamicFormFields([...props.newFormFields, ...sftpConnectorFormFields]);
      else if (updateFormValue?.type === "snowflake")
        dynamicFormFields([
          ...props.newFormFields,
          ...snowflakeConnectorFormFields,
        ]);
      if(["gcs","sftp"].includes(updateFormValue?.type)){
        setNewRowFormData({
          type: updateFormValue?.type,
          prefix: `${TENANT}-${updateFormValue?.type}-`,
          name: updateFormValue?.name,
          file_prefix:
            updateFormValue?.type === "gcs"
              ? "yyyyMMdd/{view}/"
              : "yyyy-MM-dd/{view}_\\d+.",
          file_extension: ["csv"]
        });
      }else{
        setNewRowFormData({
          type: updateFormValue?.type,
          prefix: `${TENANT}-${updateFormValue?.type}-`,
          name: updateFormValue?.name,
        });
      }
    } else setNewRowFormData(updateFormValue);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const formItemValueAfterValidation = (item, value) => {
    return {
      ...item,
      error: value,
    };
  };

  const validateFormFields = () => {
    let formFieldsList = newRowFormFields.map((item) => {
      return formItemValueAfterValidation(item, false);
    });
    setNewRowFormFields(formFieldsList);
    return formFieldsList.some((obj) => obj.error);
  };

  const payloadFormatter = () => {
    let values = {};
    Object.keys(newRowFormData)
      .filter(
        (key) =>
          !["name", "type", "prefix", "data_source_config_id"].includes(key)
      )
      .map((value) => {
        values[value] = newRowFormData[value];
      });
    let payload = {
      name: props.editFormData ? newRowFormData?.name : newRowFormData?.name?.trim() ? newRowFormData?.prefix+newRowFormData.name : newRowFormData.prefix.slice(0,newRowFormData.prefix.length-1),
      type: newRowFormData.type,
      value: { ...values },
    };
    return payload;
  };
  const testConnectionHandler = (payload) => {
    props.testConnector(payload);
  };

  const addNewConnectorDetailsToTable = (testConnectionFlag) => {
    let requiredFieldsError = false;
    let duplicates = false;
    let formFieldCheck = false;
    //  check for empty values
    [...newRowFormFields].forEach((item) => {
      // remove whitespaces or avoid white space entries
      if (newRowFormData[item.display_type !== "BooleanField"])
        newRowFormData[item.accessor] = newRowFormData[item.accessor]?.trim();
      if (item.required){
        if (
          newRowFormData[item.accessor] === false
            ? newRowFormData[item.accessor]
            : !newRowFormData[item.accessor]
        ) {
          requiredFieldsError = true;
        }
        if(newRowFormData[item.accessor] &&  !newRowFormData[item.accessor].length){
          requiredFieldsError = true;
        } 
      }
    });

    if (requiredFieldsError) {
      displaySnackMessages(
        "Please Enter the data in all the required fields",
        "error"
      );
    } else {
      formFieldCheck = validateFormFields();
    }
    // To check duplicate name entries
    !props.editFormData &&
      props.tableData.forEach((row) => {
        if (row.name === (newRowFormData?.name?.trim() ? newRowFormData?.prefix+newRowFormData?.name : newRowFormData?.prefix.slice(0,newRowFormData?.prefix.length-1))) {
          duplicates = true;
          displaySnackMessages("Entered name already exists", "warning");
        }
      });

    if (!requiredFieldsError && !formFieldCheck && !duplicates) {
      let payload = payloadFormatter();
      let reqParams = {
        ...payload
      } 
      if(payload.type === "gcs" || payload.type === "sftp"){
        reqParams.value.file_pattern = payload?.value?.file_prefix + "*.(" + payload?.value?.file_extension.join("|") + ")"
      }
      if (testConnectionFlag) {
        testConnectionHandler(reqParams);
      } else {
        // let tableData = [...addRowTableData];
        // tableData = [...tableData, newRowFormData];
        // setAddRowTableData(tableData);
        props.editFormData
          ? props.updateConnector(reqParams)
          : props.onApply(reqParams);
        // setNewRowFormData({});
      }
    }
  };

  return (
    <>
      <ConfirmPrompt
        customMinHeight={classes.customMinHeight}
        showModal={true}
        title={props.editFormData ? "Update connector" : "Add Connector"}
        message=""
        ariaLabeledBy="addUser-dialog"
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        showCloseIcon={true}
        size="lg"
        hideActionFooter={true}
        confirmCallback={(val) => {
          if (!val) {
            closeAddNewConnectorModal();
          }
        }}
      >
        <Loader loader={loader}>
          <div className={globalClasses.marginBottom}>
            <div className={classes.form}>
              <Form
                layout={"vertical"}
                maxFieldsInRow={3}
                handleChange={handleDetailsOnChange}
                fields={newRowFormFields}
                updateDefaultValue={false}
                defaultValues={newRowFormData}
                handleDropdownClose={true}
              ></Form>
              {/* <div className={globalClasses.marginLeft1rem}>
              <Button
                variant="contained"
                onClick={addNewConnectorDetailsToTable}
                id="createProductApplyBtn"
                color="primary"
                disabled={loadAddNewRowColumns}
              >
                Add
              </Button>
            </div> */}
            </div>
            {/* <Loader loader={loadAddNewRowColumns}>
            <div className={classes.tableAlignment}>
              <AgGrid
                columns={addUserTableColumns}
                rowdata={[]}
                sizeColumnsToFitFlag
                callDeleteApi={onUserDelete}
                uniqueRowId={"email"}
              />
            </div>
          </Loader> */}
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            className={globalClasses.gap}
          >
            <Button
              variant="contained"
              color="primary"
              title="Test"
              onClick={() => addNewConnectorDetailsToTable(true)}
              // disabled={props.loaderUserMgmt}
            >
              Test
            </Button>
            <Button
              variant="contained"
              color="primary"
              title="Save"
              disabled={props.loaderUserMgm}
              onClick={(val) => {
                if (val) {
                  addNewConnectorDetailsToTable();
                } else {
                  addRowTableData.length > 0
                    ? setCloseAddRowModalConfirm(true)
                    : closeAddNewConnectorModal();
                }
              }}
            >
              {props.editFormData ? "Update" : "Save"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              title="Cancel"
              onClick={setCloseAddRowModalConfirm}
              disabled={props.loaderUserMgmt}
            >
              Cancel
            </Button>
          </Grid>
        </Loader>
      </ConfirmPrompt>
      <ConfirmPrompt
        showModal={closeAddRowModalConfirm}
        title={
          props.editFormData ? "Close Update Connector" : "Close Add Connector"
        }
        message="Your changes will be discarded if you proceed. Are you sure you want to close?"
        ariaLabeledBy="close-addUser-confirmation"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setCloseAddRowModalConfirm}
        confirmCallback={(val) => {
          if (val) {
            closeAddNewConnectorModal();
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {};
};

const mapActionsToProps = {
  addSnack,
};

export default connect(mapStateToProps, mapActionsToProps)(AddConnectorModal);
